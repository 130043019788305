// LanguageContext.tsx
import { createContext, useContext, useState, ReactNode } from 'react';

// Define the possible languages
type Language = 'en' | 'de';

// Define the shape of the context
interface LanguageContextProps {
    language: Language;
    toggleLanguage: () => void;
}

// Create the context with a default value (empty object cast to the interface)
const LanguageContext = createContext<LanguageContextProps | undefined>(undefined);

// Custom hook to use the context
export const useLanguage = (): LanguageContextProps => {
    const context = useContext(LanguageContext);
    if (!context) {
        throw new Error('useLanguage must be used within a LanguageProvider');
    }
    return context;
};

// Define the props for the provider
interface LanguageProviderProps {
    children: ReactNode;
}

// Language provider component
export const LanguageProvider = ({ children }: LanguageProviderProps) => {
    const [language, setLanguage] = useState<Language>('de');

    // Function to toggle language between English and Spanish
    const toggleLanguage = () => {
        setLanguage((prev) => (prev === 'en' ? 'de' : 'en'));
    };

    return (
        <LanguageContext.Provider value={{ language, toggleLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};
