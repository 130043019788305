import { useEffect, useState } from "react";
import axios from "axios";

interface AboutMe {
  content_en: string;
  content_de: string;
}

const useFetchAboutMe = (language: string) => {
  const [aboutMe, setAboutMe] = useState<AboutMe | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchAboutMe = async () => {
      setLoading(true);
      try {
        const response = await axios.get<AboutMe[]>(
          `https://izr-cloud.online/sarah/about_me/`
        );
        setAboutMe(response.data[0]);
      } catch (err) {
        setError("Failed to fetch about me data");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchAboutMe();
  }, [language]);

  return { aboutMe, error, loading };
};

export default useFetchAboutMe;
