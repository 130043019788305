import { HStack, VStack, Image, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import translation from "../lang";
import { useLanguage } from "../LanguageContext";
import MHeading from "./MHeading";
import MText from "./MText";
import { colors } from "../theme";
import MHero from "./mobile-component/MHero";
import axios from "axios";
import izr_server from "../config";
import Devider from "./Devider";
import MySkeleton from "./MySkeleton";
import useFetchAboutMe from "./hooks/useFetchAboutMe";

interface props {
  isSmallScreen: boolean;
}

interface poem {
  content: string;
  book_name: string;
}

function Hero({ isSmallScreen }: props) {
  const { language, toggleLanguage } = useLanguage();
  const [poem, setPoem] = useState<poem>();
  const [HeroImage, setHeroImage] = useState<null | string>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { aboutMe, error } = useFetchAboutMe(language);
  useEffect(() => {
    axios.get(izr_server + "/poem/" + language).then((response) => {
      setPoem(response.data.poems[0]);
    });
    axios.get(izr_server + "/hero").then((response) => {
      setHeroImage(response.data[0]["image"]);
      setLoading(false);
    });
  }, [language]);

  if (isSmallScreen) return <MHero />;
  return (
    <VStack>
      <HStack gap={"72px"}>
        <MySkeleton width={"274px"} height={"365px"} loading={loading}>
          <Image
            src={HeroImage!}
            width={"274px"}
            height={"365px"}
            borderRadius={"15px"}
            boxShadow={"0px 0px 58px #CBB7FF"}
          ></Image>
        </MySkeleton>
        <VStack>
          <Text textAlign={"center"} maxW="450px">
            {language === "en" ? aboutMe?.content_en : aboutMe?.content_de}
          </Text>
        </VStack>
      </HStack>
      <Devider isSmallScreen={isSmallScreen} />
      <MySkeleton
        marginTop={"20px"}
        width={"520px"}
        height={"200px"}
        loading={loading}
      >
        <VStack>
          {poem?.content.split("\n").map((line, index) => (
            <Text
              key={index}
              fontStyle={"italic"}
              textAlign="left"
              maxW="500px"
            >
              {line}
            </Text>
          ))}
          <Heading fontSize={"20px"}>{poem?.book_name}</Heading>
        </VStack>
      </MySkeleton>
    </VStack>
  );
}

export default Hero;
