import { extendTheme } from '@chakra-ui/react'
import '@fontsource/inter'
import '@fontsource/open-sans'

const theme = extendTheme({
    fonts: {
        heading: `'Inter', sans-serif`,
        body: `'Inter', sans-serif`,
    },
})

export default theme

export const colors = {
    primary: "#CBB7FF",
    primary2: "#601f9e",
    secondary: "#E9D8FD",
}