import Contact from "./Contact";
import Devider from "./Devider";
import Hero from "./Hero";
import LastBook from "./LastBook";
import Section from "./Section";
import Shop from "./Shop";
import { useMediaQuery, VStack } from "@chakra-ui/react";
import Blogs from "./Blogs";

function Home() {
  const [isSmallScreen] = useMediaQuery("(max-width: 1050px)");
  return (
    <VStack>
      <Section>
        <Hero isSmallScreen={isSmallScreen} />
      </Section>
      <Devider isSmallScreen={isSmallScreen} />
      <Section>
        <LastBook isSmallScreen={isSmallScreen} />
      </Section>
      <Devider isSmallScreen={isSmallScreen} />

      <Section>
        <Shop isSmallScreen={isSmallScreen} />
      </Section>
      <Devider isSmallScreen={isSmallScreen} />

      <Section>
        <Blogs isSmallScreen={isSmallScreen} isBlog="project" />
      </Section>
      <Devider isSmallScreen={isSmallScreen} />
      <Section>
        <Blogs isSmallScreen={isSmallScreen} isBlog="blog" />
      </Section>
      <Devider isSmallScreen={isSmallScreen} />
      <Section>
        <Contact isSmallScreen={isSmallScreen} />
      </Section>
    </VStack>
  );
}

export default Home;
