import { Heading, VStack, Text, Image } from "@chakra-ui/react";
import React from "react";
import { useLanguage } from "../../LanguageContext";
import translation from "../../lang";
import hero_img from "../../design/sarah.jpeg";

function MHero() {
  const { language, toggleLanguage } = useLanguage();

  return (
    <VStack marginTop={"65px"} padding={"20px"}>
      <Heading fontSize={"35px"}>{translation.hero.welcome[language]}</Heading>
      <Text
        textAlign={"justify"}
        marginTop={"17px"}
        fontSize={"15px"}
        width={"100%"}
      >
        {translation.hero.description[language]}
      </Text>
      <Image
        src={hero_img}
        width={"274px"}
        height={"365px"}
        borderRadius={"15px"}
        boxShadow={"0px 0px 58px #CBB7FF"}
        marginTop={"35px"}
      ></Image>
    </VStack>
  );
}

export default MHero;
