import { ChakraProvider, useMediaQuery, Flex, Box } from "@chakra-ui/react";
import theme from "./theme";
import NavBar from "./components/NavBar";
import { LanguageProvider } from "./LanguageContext";
import Footer from "./components/Footer";
import Home from "./components/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Make sure to use BrowserRouter
import Impressum from "./components/Impressum";

function App() {
  const [isSmallScreen] = useMediaQuery("(max-width: 850px)");

  return (
    <ChakraProvider theme={theme}>
      <LanguageProvider>
        <Router>
          {/* Wrap the entire app in Router */}
          <Flex
            fontSize={isSmallScreen ? "xs" : "m"}
            direction="column"
            minH="100vh"
          >
            <NavBar isSmallScreen={isSmallScreen} />

            <Box flex="1">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/impressum" element={<Impressum />} />
              </Routes>
            </Box>
            <Footer isSmallScreen={isSmallScreen} />
          </Flex>
        </Router>
      </LanguageProvider>
    </ChakraProvider>
  );
}

export default App;
