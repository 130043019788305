import { HStack, Image, Link, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { colors } from "../theme";
import logo from "../design/sarahlogo.png";
import { FaInstagram } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";

interface props {
  isSmallScreen: boolean;
}

function Footer({ isSmallScreen }: props) {
  return (
    <VStack
      width={"100vw"}
      height={"300px"}
      backgroundColor={colors.primary}
      justifyContent="center"
      paddingY={"10px"}
    >
      <HStack gap={isSmallScreen ? "10px" : "30px"}>
        <Link
          href="https://www.instagram.com/elosa68?igsh=NHphN3oxNm8wN251"
          isExternal
        >
          <FaInstagram
            cursor="pointer"
            size={isSmallScreen ? "30px" : "30px"}
            color={"black"}
          />
        </Link>
        <Link
          href="https://www.tiktok.com/@elosa68?_t=8r7fxhwlwoL&_r=1"
          isExternal
        >
          <FaTiktok
            cursor="pointer"
            size={isSmallScreen ? "30px" : "30px"}
            color={"black"}
          />
        </Link>
      </HStack>
      <VStack>
        <Image src={logo} width={"200px"} height={"100px"}></Image>
        <VStack alignItems={"end"}>
          <Text color={"black"} fontSize={"s"}>
            Sarah Foudhaili ©
          </Text>
          <Text color={"black"} fontSize={"s"}>
            Designed By MAB
          </Text>
        </VStack>
      </VStack>
    </VStack>
  );
}

export default Footer;
