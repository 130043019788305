import { VStack, Heading, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import MButton from "./MButton";
import { useLanguage } from "../LanguageContext";
import translation from "../lang";
import MySkeleton from "./MySkeleton";

interface props {
  name: string;
  link: string;
  desc: string;
  price: string;
  img: string;
  isSmallScreen?: boolean;
}

function Artikel({ name, link, desc, img, price, isSmallScreen }: props) {
  const { language, toggleLanguage } = useLanguage();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [language]);

  return (
    <VStack
      marginY={"40px"}
      alignItems={"start"}
      // border={"1px solid lightgray"}
      height={"100%"}
      padding={"20px"}
      borderRadius={"10px"}
      shadow={"0px 0px 10px 0.1px lightgray"}
    >
      <MySkeleton width={"240px"} height={"375px"} loading={loading}>
        <Image
          borderRadius={"10px"}
          overflow={"hidden"}
          src={img}
          width={"240px"}
          height={"375px"}
        ></Image>
      </MySkeleton>
      <Heading fontSize={"2xl"} textAlign={"left"}>
        {name}
      </Heading>
      <Text>{desc}</Text>
      <Heading>
        {price}
        <sup>€</sup>
      </Heading>
      <MButton
        variant="large"
        txt={translation.buy[language]}
        onClick={() => window.open(link)}
      />
    </VStack>
  );
}

export default Artikel;
