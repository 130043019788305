import { useEffect, useState } from "react";
import axios from "axios";

// Define interfaces for the data structure
interface ContentItem {
  id: number;
  content_type: string;
  text: string;
  image: string | null;
  v_image: boolean;
}

interface BlogPost {
  id: number;
  title: string;
  author: string;
  created_at: string;
  updated_at: string;
  type: string;
  content_items: ContentItem[];
}

// Modify the custom hook
const useFetchBlogPosts = (language: string) => {
  const [blogPosts, setBlogPosts] = useState<BlogPost[]>([]); // Changed to array type
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchBlogPosts = async () => {
      setLoading(true);
      try {
        const response = await axios.get<{ blogs: BlogPost[] }>(
          "https://izr-cloud.online/sarah/blog/" + language
        );
        setBlogPosts(response.data.blogs); // Now this should work correctly
      } catch (err) {
        setError("Failed to fetch blog posts");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogPosts();
  }, [language]);

  return { blogPosts, error, loading };
};

export default useFetchBlogPosts;
