import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  Textarea,
  Text,
} from "@chakra-ui/react";
import { Field, Form, Formik, FormikProps, FormikHelpers } from "formik";
import axios from "axios";
import { useState } from "react";
import { useLanguage } from "../LanguageContext";

// Define the shape of form values
interface FormValues {
  name: string;
  email: string;
  message: string;
}
interface Props {
  isSmallScreen: boolean;
}

function FormikExample({ isSmallScreen }: Props) {
  const { language } = useLanguage();
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  // Validation functions
  const validateName = (value: string) => {
    let error;
    if (!value) {
      error = "Name is required";
    }
    return error;
  };

  const validateEmail = (value: string) => {
    let error;
    if (!value) {
      error = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(value)) {
      error = "Email address is invalid";
    }
    return error;
  };

  const validateMessage = (value: string) => {
    let error;
    if (!value) {
      error = "Message is required";
    }
    return error;
  };

  // The component rendering
  return (
    <Formik
      initialValues={{ name: "", email: "", message: "" }}
      onSubmit={async (
        values: FormValues,
        actions: FormikHelpers<FormValues>
      ) => {
        try {
          const response = await axios.post(
            "https://izr-cloud.online/sarah/messages/",
            values
          );
          console.log("Server response:", response.data);

          // Show success message and reset form fields
          setSuccessMessage(
            language === "en"
              ? "Message sent successfully!"
              : "Nachricht erfolgreich gesendet!"
          );
          actions.setSubmitting(false);
          actions.resetForm();

          // Clear the success message after 3 seconds
          setTimeout(() => setSuccessMessage(null), 3000);
        } catch (error) {
          console.error("Error submitting form:", error);
          actions.setSubmitting(false);
        }
      }}
    >
      {(props: FormikProps<FormValues>) => (
        <Form>
          <Field name="name" validate={validateName}>
            {({ field, form }: any) => (
              <FormControl
                width={isSmallScreen ? "350px" : "500px"}
                isInvalid={form.errors.name && form.touched.name}
              >
                <FormLabel>Name</FormLabel>
                <Input {...field} placeholder="Name" />
                <FormErrorMessage>{form.errors.name}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name="email" validate={validateEmail}>
            {({ field, form }: any) => (
              <FormControl
                isInvalid={form.errors.email && form.touched.email}
                mt={4}
              >
                <FormLabel>Email</FormLabel>
                <Input {...field} placeholder="Email" type="email" />
                <FormErrorMessage>{form.errors.email}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name="message" validate={validateMessage}>
            {({ field, form }: any) => (
              <FormControl
                isInvalid={form.errors.message && form.touched.message}
                mt={4}
              >
                <FormLabel>
                  {language === "en" ? "Message" : "Nachricht"}
                </FormLabel>
                <Textarea
                  {...field}
                  placeholder={
                    language === "en" ? "Your Message" : "Deine Nachricht"
                  }
                />
                <FormErrorMessage>{form.errors.message}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Button
            marginTop={"10px"}
            isLoading={props.isSubmitting}
            type="submit"
            colorScheme="purple"
          >
            {language === "en" ? "Send Message" : "Nachricht Senden"}
          </Button>

          {successMessage && (
            <Text color="green.500" mt={4}>
              {successMessage}
            </Text>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default FormikExample;
