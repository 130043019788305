import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
  Text,
  VStack,
  Heading,
  Image,
} from "@chakra-ui/react";
import useFetchBlogPosts from "./hooks/useFetchBlogPosts";
import { useLanguage } from "../LanguageContext";

interface Props {
  isSmallScreen: boolean;
  isBlog: string;
}

function Blogs({ isSmallScreen, isBlog }: Props) {
  const { language } = useLanguage();
  const { blogPosts, error, loading } = useFetchBlogPosts(language);

  const formatText = (text: string) =>
    text.split("\n").map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));

  if (error) return <Text>Server Error</Text>;
  if (loading) return <Text>Loading...</Text>;

  // Filter blog posts based on the type
  const filteredPosts = blogPosts?.filter((post) => post.type === isBlog);

  return (
    <VStack width="100%" id="blog">
      <Heading
        textAlign="left"
        width={isSmallScreen ? "100vw" : "1050px"}
        padding={isSmallScreen ? "20px" : "0px"}
        fontSize={isSmallScreen ? "35px" : "64px"}
      >
        {isBlog === "blog" ? "Blogs" : "Projects"}
      </Heading>

      {filteredPosts && filteredPosts.length > 0 ? (
        <Accordion width="100%" allowMultiple>
          {filteredPosts.map((blogPost) => (
            <AccordionItem key={blogPost.id}>
              <h2>
                <AccordionButton>
                  <Box
                    fontWeight="semibold"
                    fontSize="xl"
                    as="span"
                    flex="1"
                    textAlign="left"
                  >
                    {blogPost.title}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                {blogPost.content_items.map((item, idx) => (
                  <VStack width="100%" key={idx} spacing={3}>
                    {item.content_type === "paragraph" && (
                      <VStack width="100%">
                        <Text fontSize={"s"} width="100%">
                          {formatText(item.text)}
                        </Text>
                        <br />
                      </VStack>
                    )}
                    {item.content_type === "header" && (
                      <Heading fontSize="l" width="100%">
                        {item.text}
                      </Heading>
                    )}
                    {item.image && (
                      <Image
                        width={isSmallScreen ? "95%" : "40%"}
                        borderRadius="10px"
                        overflow="hidden"
                        src={item.image}
                        alt={`Image for ${blogPost.title}`}
                      />
                    )}
                  </VStack>
                ))}
                <Text width="100%" fontWeight={"bold"} fontSize={"s"}>
                  {language === "de" ? "Autor: " : "Author: "}
                  {blogPost.author}
                </Text>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      ) : (
        <Text padding={"20px"} textAlign={"left"} width={"100%"}>
          {language === "de" ? "Inhalt in Kürze! " : "Content Coming Soon ! "}{" "}
        </Text> // Optional message if no posts match
      )}
    </VStack>
  );
}

export default Blogs;
