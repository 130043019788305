import { useMediaQuery } from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface SectionProps {
  children: ReactNode;
}

const Section = ({ children }: SectionProps) => {
  const [isSmallScreen] = useMediaQuery("(max-width: 800px)");

  return (
    <div
      style={{
        maxWidth: "1050px",
        marginTop: isSmallScreen ? "10px" : "35px",
        marginBottom: isSmallScreen ? "10px" : "35px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {children}
    </div>
  );
};

export default Section;
