import { Box, VStack, Image, Stack, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { colors } from "../theme"; // Ensure colors.primary is valid in your theme
import MButton from "./MButton";
import translation from "../lang";
import { useLanguage } from "../LanguageContext";
import axios from "axios";
import izr_server from "../config";
import MySkeleton from "./MySkeleton";

interface props {
  isSmallScreen: boolean;
}

interface product {
  name: string;
  photo: string;
  poem: string;
  price: string;
  amazon_link: string;
}

function LastBook({ isSmallScreen }: props) {
  const { language, toggleLanguage } = useLanguage();
  const [product, setProduct] = useState<product>();
  const [loading, setloading] = useState(true);

  useEffect(() => {
    axios.get(izr_server + "/last_product/" + language).then((response) => {
      setProduct(response.data.last_product[0]);
      setloading(false);
    });
    console.log("ass");
  }, [language]);

  return (
    <MySkeleton
      width={isSmallScreen ? "350px" : "1050px"}
      minHeight="350px"
      loading={loading}
    >
      <Stack
        direction={isSmallScreen ? "column" : "row"}
        _hover={{ transform: "scale(1.05)", transition: "ease 0.2s" }}
        gap={10} // Chakra UI accepts numbers for spacing, this will be 100px
        borderRadius="10px"
        width={isSmallScreen ? "350px" : "1050px"}
        minHeight="350px"
        backgroundColor={colors.secondary}
        justifyContent="left"
        alignItems={"center"}
        padding={"20px"}
      >
        <Box
          width="170px"
          height="250px"
          backgroundColor="lightgray"
          borderRadius="10px"
          overflow="hidden"
          aria-label="Book cover"
        >
          <Image src={product?.photo} width="170px" height="250px"></Image>
        </Box>
        <VStack align="left">
          <Heading>{product?.name}</Heading>
          {product?.poem.split("\n").map((line, index) => (
            <Text fontStyle={"italic"} key={index}>
              {line}
            </Text>
          ))}
          <Heading>
            {product?.price}
            <sup>€</sup>
          </Heading>
          <MButton
            txt={translation.buy[language]}
            variant="large"
            onClick={() => window.open(product?.amazon_link)}
          />
        </VStack>
      </Stack>
    </MySkeleton>
  );
}

export default LastBook;
