const translation = {
  nav: {
    contact: {
      de: "Kontakt",
      en: "Contact",
    },
    impressum: {
      de: "Impressum",
      en: "Imprint",
    },
  },
  hero: {
    welcome: {
      de: "Willkommen",
      en: "Welcome",
    },
    description: {
      de: "Sarah Foudhaili ist eine Sozialpädagogin mit einer Leidenschaft für das Schreiben. Geboren in Aachen und aufgewachsen in Tunesien, ist sie mit 13 Jahren nach Deutschland zurückgekehrt. Schon früh hat sie die Macht der Literatur erkannt und über die damalige Diktatur in Tunesien geschrieben.In ihrer Familie spielt Literatur eine zentrale Rolle: Einige lesen begeistert, andere schreiben Prosa oder veröffentlichen arabische Poesie. Auch Sarah hat das Schreiben stets geliebt, und ein paar dürften sie noch durch Wattpad kennenSarah Foudhailis Werke zeichnen sich aus durch emotionale Tiefe, Empathie und dem Appell an den gottgegebenen Menschenverstand. Ihre Werke sind eine Mischung aus persönlicher Reflexion, gesellschaftlicher Kritik und der Suche nach Schönheit in der Welt.",
      en: "Sarah Foudhaili is a social pedagogue with a passion for writing. Born in Aachen and raised in Tunisia, she returned to Germany at the age of 13. She recognised the power of literature early on and wrote about the dictatorship in Tunisia at the time, and literature plays a central role in her family: some read enthusiastically, others write prose or publish Arabic poetry. Sarah has always loved writing too, and some people may still know her from WattpadSarah Foudhaili's works are characterised by emotional depth, empathy and an appeal to God-given common sense. Her works are a mixture of personal reflection, social criticism and the search for beauty in the world.",
    },
  },
  buy: {
    de: "Bestellen",
    en: "Order Now",
  },
  contact: {
    de: "Haben Sie Fragen zu unserem Angebot? Unser kompetentes Team ist gerne für Sie da. Füllen Sie einfach das Kontaktformular aus, und wir setzen uns zeitnah mit Ihnen in Verbindung. Natürlich können Sie uns auch telefonisch erreichen – wir freuen uns von Ihnen zu hören!",
    en: "Do you have any questions about our range? Our competent team will be happy to help you. Simply fill out the contact form and we will get in touch with you as soon as possible. Of course you can also contact us by phone - we look forward to hearing from you!",
  },
  voices_palestine: {
    q1: {
      de: "Warum ist dieses Projekt wichtig?",
      en: "Why is this project important?",
    },
    q2: {
      de: "Wie unterstützt dieses Projekt Palästina?",
      en: "How does this project support Palestine?",
    },
    q3: {
      de: "Wie kannst du teilnehmen?",
      en: "How can you take part?",
    },
    p1: {
      de: "ein deutschlandweites Projekt, das dazu einlädt, durch die Kraft der Poesie und des geschriebenen Wortes Unterstützung und Solidarität mit dem palästinensischen Volk zum Ausdruck zu bringen. Wir laden Menschen aller Altersgruppen, Hintergründe und Kulturen ein, ihre Gedichte und kurze Texte einzureichen, um gemeinsam eine Sammlung zu schaffen, die die Vielfalt der Empfindungen, Gedanken und Erfahrungen rund um Palästina einfängt.",
      en: "a Germany-wide project that invites people to express support and solidarity with the Palestinian people through the power of poetry and the written word. We invite people of all ages, backgrounds and cultures to submit their poems and short texts to collectively create a collection that captures the diversity of feelings, thoughts and experiences surrounding Palestine.",
    },
    p2: {
      de: "Palästina ist ein Ort, der nun seit 8 Monaten unter dem brutalen Genozid leidet. Doch hinter den Schlagzeilen gibt es eine reiche Kultur, eine lebendige Geschichte und vor allem Menschen, die unter den unzumutbaren Bedingungen der Kriegsverbrechen leben müssen.",
      en: "Palestine is a place that has been suffering from brutal genocide for 8 months now. But behind the headlines there is a rich culture, a living history and, above all, people who have to live under the unacceptable conditions of war crimes.",
    },
    p3: {
      de: "Doch es gibt Menschen, wie du und ich, die ihre Stimme erheben und ihre Geschichten erzählen möchten. Die Menschen in Palästina müssen gehört werden. Sie müssen wissen, dass die Bürger der ganzen Welt mit ihnen sind, auch wenn ihre Regierungen die Kriegsverbrechen unterstützen.",
      en: "But there are people, like you and me, who want to raise their voices and tell their stories. The people of Palestine need to be heard. They need to know that the citizens of the whole world are with them, even if their governments support the war crimes.",
    },
    p4: {
      de: "Die Menschen in Palästina brauchen unsere Hilfe, sowohl in Wort als auch finanziell. Durch dieses Projekt möchten wir eine Plattform bieten, um diese Geschichten zu teilen und gleichzeitig einen positiven Beitrag zu leisten, indem wir die Einnahmen aus dem Verkauf allesamt an Palästina spenden. Jeder Beitrag, jedes Gedicht ist ein Zeichen der Unterstützung und Solidarität, das den Menschen in Palästina Hoffnung und Stärkung geben kann.",
      en: "The people of Palestine need our help, both verbally and financially. Through this project, we want to provide a platform to share these stories and at the same time make a positive contribution by donating all proceeds from sales to Palestine. Every contribution, every poem is a sign of support and solidarity that can give hope and strength to the people of Palestine.",
    },
    p5: {
      de: "Die Einnahmen aus dem Verkauf dieses Projekts werden direkt an ausgewählte gemeinnützige Organisationen und Initiativen in Palästina gespendet. Diese Organisationen arbeiten vor Ort, um Bildung, medizinische Versorgung, humanitäre Hilfe und andere dringend benötigte Dienstleistungen bereitzustellen, um die Lebensbedingungen der Menschen in Palästina zu verbessern. Durch das Mitmachen und/oder den Kauf des Buches tragen Sie dazu bei, positive Veränderungen in den Gemeinschaften vor Ort zu bewirken und den Menschen in Palästina Hoffnung und Unterstützung zu geben.",
      en: "The proceeds from the sale of this project will be donated directly to selected non-profit organizations and initiatives in Palestine. These organizations are working on the ground to provide education, medical care, humanitarian aid and other much-needed services to improve the lives of the people of Palestine. By participating and/or purchasing the book, you are helping to bring about positive change in local communities and provide hope and support to the people of Palestine.Translated with DeepL.com (free version)",
    },
    p6: {
      de: "Es ist ganz einfach! Schreibe bis zum 30. Juni 2024 ein Gedicht oder einen kurzen Text zum Thema Palästina und reiche es über die Email: sarah.foudhaili@yahoo.com ein.\nDeine Email soll folgendes enthalten, damit sie gewertet werden kann:",
      en: "It's very simple! Write a poem or a short text on the topic of Palestine by June 30, 2024 and submit it via the email: sarah.foudhaili@yahoo.com.\nYour email should contain the following so that it can be evaluated:",
    },
    p7: {
      de: [
        "1-5 Gedichte oder kurze Texte von dir verfasst (max. 7200 Zeichen inkl. Leerzeichen pro Gedicht/Text)",
        "Dein voller Name oder dein Pseudonym, unter dem du veröffentlichen willst (wird auf der 1. Seite des Buchs veröffentlicht)",
        "falls unter 18: formlose Einverständniserklärung der Eltern (mit Unterschrift!)",
        "Deine Nummer und deine Adresse (nur für Verwertungszwecke und für das Zuschicken deines Exemplars)",
        "antisemitische,  hetzende Gedichte und Texte werden nicht toleriert und sind von dem Projekt ausgeschlossen! Wir wollen helfen und nicht noch mehr Hass verbreiten, als die Welt eh schon hat...",
      ],
      en: [
        "1-5 poems or short texts written by you (max. 7200 characters incl. spaces per poem/text)",
        "Your full name or pseudonym under which you want to publish (will be published on the 1st page of the book)",
        "if under 18: informal declaration of consent from parents (with signature!)",
        "Your number and address (only for utilization purposes and for sending your copy)",
        "Anti-Semitic, inflammatory poems and texts will not be tolerated and are excluded from the project! We want to help and not spread even more hate than the world already has...",
      ],
    },
    p8: {
      de: "Jeder ist willkommen, unabhängig von Alter, Nationalität oder künstlerischem Hintergrund. Deine Stimme zählt und kann den Unterschied machen.",
      en: "Everyone is welcome, regardless of age, nationality or artistic background. Your voice counts and can make the difference.",
    },
    p9: {
      de: "100 Gedichte/Texte werden dann zur Veröffentlichung ausgewählt. Sprich die Chance, dass dein Text ausgewählt wird, ist groß.",
      en: "100 poems/texts will then be selected for publication. In other words, there is a good chance that your text will be selected.",
    },
    p10: {
      de: "Gemeinsam können wir ein Buch der Hoffnung schaffen und einen Beitrag dazu leisten, das Leben der Menschen in Palästina zu verbessern. Lasst uns unsere Stimmen erheben und gemeinsam eine positive Veränderung bewirken.",
      en: "Together we can create a book of hope and make a contribution to improving the lives of people in Palestine. Let's raise our voices and make a positive change together.",
    },
  },
};

export default translation;
