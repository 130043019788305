import React from "react";
import { useLanguage } from "../LanguageContext";
import MHeading from "./MHeading";
import translation from "../lang";
import { Heading, HStack, Stack, VStack, Text } from "@chakra-ui/react";
import MText from "./MText";
import ContactForm from "./ContactForm";

interface props {
  isSmallScreen: boolean;
}

function Contact({ isSmallScreen }: props) {
  const { language, toggleLanguage } = useLanguage();

  return (
    <VStack
      id="contact"
      alignItems={"flex-start"}
      width={isSmallScreen ? "100vw" : "1050px"}
      justifyContent={"start"}
      padding={isSmallScreen ? "20px" : "0px"}
    >
      <Heading fontSize={isSmallScreen ? "35px" : "64px"}>
        {translation.nav.contact[language]}
      </Heading>
      <Stack
        display={"flex"}
        direction={isSmallScreen ? "column" : "row"}
        width={"100%"}
        alignItems={"start"}
        justifyContent={"space-between"}
      >
        <Text textAlign="justify" maxW={isSmallScreen ? "100%" : "400px"}>
          {translation.contact[language]}
        </Text>
        <ContactForm isSmallScreen={isSmallScreen} />
      </Stack>
    </VStack>
  );
}

export default Contact;
