import {
  Button,
  Text,
  VStack,
  Image,
  HStack,
  Switch,
  IconButton,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { colors } from "../../theme";
import logo from "../../design/sarahlogo.png";
import { useLanguage } from "../../LanguageContext";
import translation from "../../lang";
import { useNavigate } from "react-router-dom";
import { CloseButton, HamburgerIcon } from "@chakra-ui/icons";

const NAVBAR_HEIGHT_COLLAPSED = "100px";
const NAVBAR_HEIGHT_EXPANDED = "590px";

const MNavBar = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [targetHash, setTargetHash] = useState<string | null>(null);
  const { language, toggleLanguage } = useLanguage();
  const navigate = useNavigate();

  const toggleView = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleNavigation = (path: string, hash: string) => {
    navigate(path);
    setTargetHash(hash); // Set the target hash to scroll to
    toggleView(); // Toggle the view
  };

  useEffect(() => {
    if (targetHash) {
      const section = document.getElementById(targetHash.substring(1)); // Remove '#' from the hash
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
      // Reset the target hash after scrolling
      setTargetHash(null);
    }
  }, [targetHash]); // Run this effect when targetHash changes

  return (
    <VStack
      justifyContent="end"
      alignItems="center"
      backgroundColor={colors.primary}
      height={isExpanded ? NAVBAR_HEIGHT_EXPANDED : NAVBAR_HEIGHT_COLLAPSED}
      width="100vw"
      spacing={0}
      transition="ease 1s"
      borderBottomRadius="10px"
    >
      {isExpanded && (
        <VStack spacing="35px">
          <Button
            onClick={() => handleNavigation("/", "#shop")}
            width="300px"
            size="lg"
            colorScheme="purple"
          >
            Shop
          </Button>
          <Button
            onClick={() => handleNavigation("/", "#blog")}
            width="300px"
            size="lg"
            colorScheme="purple"
          >
            Blog
          </Button>
          <Button
            onClick={() => handleNavigation("/", "#blog")}
            width="300px"
            size="lg"
            colorScheme="purple"
          >
            {language === "en" ? "Projects" : "Projekte"}
          </Button>
          <Button
            onClick={() => handleNavigation("/impressum", "")} // No hash for this one
            width="300px"
            size="lg"
            colorScheme="purple"
          >
            {translation.nav.impressum[language]}
          </Button>
          <Button
            onClick={() => handleNavigation("/", "#contact")}
            width="300px"
            size="lg"
            colorScheme="purple"
          >
            {translation.nav.contact[language]}
          </Button>

          <HStack>
            <Switch colorScheme="purple" onChange={toggleLanguage} />
            <Text>{language.toUpperCase()}</Text>
          </HStack>
        </VStack>
      )}
      <HStack width="100%" paddingX="20px" justifyContent="space-between">
        <Image
          src={logo}
          width="150px"
          height="68px"
          marginBottom="5px"
          cursor="pointer"
          onClick={() => navigate("/")}
        />
        <IconButton
          colorScheme="gray"
          aria-label={isExpanded ? "Close menu" : "Open menu"}
          onClick={toggleView}
          height="50px"
          width="50px"
          icon={isExpanded ? <CloseButton /> : <HamburgerIcon />}
        />
      </HStack>
    </VStack>
  );
};

export default MNavBar;
