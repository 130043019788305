import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Heading,
  Text,
  Spinner,
  Alert,
  AlertIcon,
  useMediaQuery,
} from "@chakra-ui/react";
import { useLanguage } from "../LanguageContext";

// Define the interface for the imprint data
interface Imprint {
  title: string;
  angaben_gem_tmg: string;
  kontaktaufnahme: string;
  haftung_inhalte: string;
  haftung_externe_links: string;
  urheberrecht: string;
  widerrufsrecht: string;
  folgen_widerruf: string;
  ausschluss_erloeschensgruende: string;
  updated_at: string;
}

function Impressum() {
  // State to hold the imprint data
  const { language } = useLanguage(); // Removed toggleLanguage as it's unused
  const [isSmallScreen] = useMediaQuery("(max-width: 850px)");

  const [imprint, setImprint] = useState<Imprint | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Fetch imprint data when the component mounts
  useEffect(() => {
    const fetchImprint = async () => {
      setLoading(true);

      try {
        const response = await axios.get<Imprint[]>(
          "https://izr-cloud.online/sarah/imprint/"
        );
        if (response.data.length > 0) {
          setImprint(response.data[language === "de" ? 0 : 1]); // Grab only the first imprint
        } else {
          setError("No imprint data available.");
        }
      } catch (err) {
        setError("Failed to fetch imprint data.");
      } finally {
        setLoading(false);
      }
    };

    fetchImprint();
  }, [language]);

  // If loading, show a spinner
  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }
  // Custom formatText function
  const formatText = (text: string) => {
    if (!text) return "";
    return text.split("\n").map((line, index) => (
      <span key={index}>
        {line[1] === "-" ? "" + line.slice(1) : line}
        <br />
      </span>
    ));
  };
  // If there’s an error, show an alert
  if (error) {
    return (
      <Box p={5}>
        <Alert status="error">
          <AlertIcon />
          {error}
        </Alert>
      </Box>
    );
  }

  // Render the imprint content if available
  return (
    <Box width={isSmallScreen ? "100%" : "1050px"} p={"30px"}>
      <Heading fontSize={isSmallScreen ? "35px" : "64px"} mb={4}>
        {formatText(imprint?.title!)}
      </Heading>

      <Heading fontSize={isSmallScreen ? "20px" : "25px"}>
        Angaben gemäß §5 TMG:
      </Heading>
      <Text mb={4}>{formatText(imprint?.angaben_gem_tmg!)}</Text>

      <Heading fontSize={isSmallScreen ? "20px" : "25px"}>
        Kontaktaufnahme:
      </Heading>
      <Text mb={4}>{formatText(imprint?.kontaktaufnahme!)}</Text>

      <Heading fontSize={isSmallScreen ? "20px" : "25px"}>
        Haftung für Inhalte:
      </Heading>
      <Text mb={4}>{formatText(imprint?.haftung_inhalte!)}</Text>

      <Heading fontSize={isSmallScreen ? "20px" : "25px"}>
        Haftung für externe Links:
      </Heading>
      <Text mb={4}>{formatText(imprint?.haftung_externe_links!)}</Text>

      <Heading fontSize={isSmallScreen ? "20px" : "25px"}>
        Urheberrecht:
      </Heading>
      <Text mb={4}>{formatText(imprint?.urheberrecht!)}</Text>

      <Heading fontSize={isSmallScreen ? "20px" : "25px"}>
        Widerrufsrecht:
      </Heading>
      <Text mb={4}>{formatText(imprint?.widerrufsrecht!)}</Text>

      <Heading fontSize={isSmallScreen ? "20px" : "25px"}>
        Folgen des Widerrufs:
      </Heading>
      <Text mb={4}>{formatText(imprint?.folgen_widerruf!)}</Text>

      <Heading fontSize={isSmallScreen ? "20px" : "25px"}>
        Ausschluss- und Erlöschensgründe:
      </Heading>
      <Text mb={4}>{formatText(imprint?.ausschluss_erloeschensgruende!)}</Text>

      <Heading fontSize={isSmallScreen ? "20px" : "25px"} mt={6}>
        Zuletzt aktualisiert:
      </Heading>
      <Text mb={4}>{new Date(imprint?.updated_at!).toLocaleDateString()}</Text>
    </Box>
  );
}

export default Impressum;
