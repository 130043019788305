import { Box, HStack, Image } from "@chakra-ui/react";
import React from "react";
import logo from "../design/sarahlogo.png";
import { colors } from "../theme";

interface props {
  isSmallScreen: boolean;
}

function Devider({ isSmallScreen }: props) {
  return (
    <HStack
      marginY={"20px"}
      width={!isSmallScreen ? "1050px" : "100vw"}
      justifyContent={"space-evenly"}
    >
      <Box
        minWidth={!isSmallScreen ? "400px" : "200px"}
        height={"2px"}
        backgroundColor={colors.primary}
        borderRadius={"2.5"}
      ></Box>
      <Image src={logo} width={"60px"} height={"30px"}></Image>
      <Box
        minWidth={!isSmallScreen ? "400px" : "200px"}
        minH={"2px"}
        backgroundColor={colors.primary}
        borderRadius={"2.5"}
      ></Box>
    </HStack>
  );
}

export default Devider;
