import React, { ReactNode } from "react";
import { Box, SkeletonCircle, SkeletonText, BoxProps } from "@chakra-ui/react";

interface MySkeletonProps extends BoxProps {
  loading: boolean;
  children: ReactNode;
}

const MySkeleton: React.FC<MySkeletonProps> = ({
  loading,
  children,
  ...styleProps
}) => {
  return (
    <Box {...styleProps}>
      {loading ? (
        <Box
          {...styleProps}
          borderRadius={10}
          padding="6"
          boxShadow="lg"
          bg="white"
        >
          <SkeletonCircle size="10" />
          <SkeletonText mt="4" noOfLines={4} spacing="4" skeletonHeight="2" />
        </Box>
      ) : (
        children
      )}
    </Box>
  );
};

export default MySkeleton;
