import { GridItem, Heading, SimpleGrid, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import axios from "axios";
import izr_server from "../config";
import MySkeleton from "./MySkeleton";
import Artikel from "./Artikel";
import { useLanguage } from "../LanguageContext";

interface Props {
  isSmallScreen: boolean;
}

interface Product {
  id: number;
  name: string;
  photo: string;
  description: string;
  price: string;
  amazon_link: string;
}

function Shop({ isSmallScreen }: Props) {
  const { language } = useLanguage();
  const [products, setProducts] = useState<Product[]>([]);
  const [imageLoading, setImageLoading] = useState<{ [key: number]: boolean }>(
    {}
  );

  useEffect(() => {
    axios.get(`${izr_server}/products/${language}`).then((response) => {
      setProducts(response.data.products);
    });
  }, [language]);

  return (
    <VStack
      id="shop"
      width={isSmallScreen ? "100vw" : "1050px"}
      alignItems={"center"}
    >
      <Heading
        textAlign="left"
        width={isSmallScreen ? "100vw" : "1050px"}
        padding={isSmallScreen ? "20px" : "0px"}
        fontSize={isSmallScreen ? "35px" : "64px"}
      >
        Shop
      </Heading>
      <VStack>
        <SimpleGrid
          marginY={"35px"}
          gap={10}
          columns={isSmallScreen ? 1 : 2}
          padding={"20px"}
        >
          {products.map((artikel) => (
            <GridItem key={artikel.id}>
              <Artikel
                name={artikel.name}
                link={artikel.amazon_link}
                desc={artikel.description}
                img={artikel.photo}
                price={artikel.price}
                isSmallScreen={false}
              />
            </GridItem>
          ))}
        </SimpleGrid>
      </VStack>
    </VStack>
  );
}

export default Shop;
