import { Button, ButtonProps } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { colors } from "../theme";

interface MButtonProps extends ButtonProps {
  txt: string;
  variant: "normal" | "large" | "xlarge";
  onClick: () => void;
}

function MButton({ txt, variant, onClick, ...rest }: MButtonProps) {
  const [size, setSize] = useState({ x: "94px", y: "32px" });

  useEffect(() => {
    if (variant === "large") {
      setSize({ x: "150px", y: "32px" });
    } else if (variant === "xlarge") {
      setSize({ x: "454px", y: "32px" });
    } else {
      setSize({ x: "94px", y: "32px" });
    }
  }, [variant]);

  return (
    <Button
      _hover={{ backgroundColor: colors.primary, transform: "scale(1.1)" }}
      onClick={onClick}
      width={size.x}
      height={size.y}
      fontWeight={"900"}
      color={"white"}
      colorScheme="purple"
      {...rest} // Spread the extra props here
    >
      {txt}
    </Button>
  );
}

export default MButton;
