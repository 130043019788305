import {
  HStack,
  IconButton,
  Image,
  Link,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";

import logo from "../design/sarahlogo.png";
import MButton from "./MButton";
import { Switch } from "@chakra-ui/react";
import { useLanguage } from "../LanguageContext";
import { colors } from "../theme";
import translation from "../lang";
import MNavBar from "./mobile-component/MNavBar";
import { useNavigate } from "react-router-dom";

interface props {
  isSmallScreen: boolean;
}

function NavBar({ isSmallScreen }: props) {
  const { language, toggleLanguage } = useLanguage();
  const navigate = useNavigate();

  if (isSmallScreen) return <MNavBar />;

  return (
    <HStack
      alignSelf={"center"}
      minWidth={"1250px"}
      minHeight={"80px"}
      justifyContent={"space-between"}
      padding={"4px"}
      borderRadius={"8px"}
      shadow={"0px 0px 10px 0px lightgrey"}
      marginY={"35px"}
    >
      <Image
        marginLeft={"22px"}
        src={logo}
        width={"180px"}
        height={"80px"}
        cursor={"pointer"}
        onClick={() => navigate("/")}
      ></Image>
      <HStack marginRight={"22px"}>
        <HStack gap={"35px"}>
          <Link
            _hover={{ fontWeight: "bold" }}
            onClick={() => {
              navigate("/");
              setTimeout(() => {
                window.location.hash = "#blog";
              }, 200);
            }}
            style={{ textDecoration: "none" }}
            variant={""}
          >
            {language === "en" ? "Projects" : "Projekte"}
          </Link>
          <Link
            _hover={{ fontWeight: "bold" }}
            textDecoration={"none"}
            style={{ textDecoration: "none" }}
            onClick={() => {
              navigate("/");
              setTimeout(() => {
                window.location.hash = "#contact";
              }, 200);
            }}
          >
            {translation.nav.contact[language]}
          </Link>
          <Link
            _hover={{ fontWeight: "bold" }}
            textDecoration={"none"}
            style={{ textDecoration: "none" }}
            onClick={() => navigate("/impressum")}
          >
            {translation.nav.impressum[language]}
          </Link>
          <Link
            _hover={{ fontWeight: "bold" }}
            textDecoration={"none"}
            style={{ textDecoration: "none" }}
            onClick={() => {
              navigate("/");
              setTimeout(() => {
                window.location.hash = "#blog";
              }, 200);
            }}
          >
            Blog
          </Link>
          <MButton
            txt="Shop"
            onClick={() => {
              navigate("/");
              setTimeout(() => {
                window.location.hash = "#shop";
              }, 200);
            }}
            variant="normal"
          ></MButton>
        </HStack>
        <Switch colorScheme="purple" onChange={toggleLanguage} />
        <Text>{language.toUpperCase()}</Text>
      </HStack>
    </HStack>
  );
}

export default NavBar;
